
import { defineComponent, ref } from "vue"
import { ElForm } from "element-plus"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import { getRule, RuleTypes } from "@/core/helpers/rules"

export default defineComponent({
  setup() {
    const isSignInLoading = ref(false)
    const store = useStore()
    const router = useRouter()
    const ruleFormRef = ref<FormInstance>()
    type FormInstance = InstanceType<typeof ElForm>

    const rules = {
      username: getRule(RuleTypes.TEXT50, "Kullanıcı Adı"),
      password: getRule(RuleTypes.TEXT50, "Şifre"),
    }
    const signIn = ref({
      username: "",
      password: "",
    })

    function onSubmitSignIn(formEl) {
      formEl.validate(async valid => {
        if (valid) {
          isSignInLoading.value = true
          store.dispatch(Actions.LOGOUT)
          try {
            await store.dispatch(Actions.LOGIN, signIn.value)
            await store.dispatch(Actions.GET_MENU)
            await store.dispatch(Actions.GET_ACCOUNT)
            await store.dispatch(Actions.GET_WIDGET)

            router.push({ name: "dashboard-summary" })
          } finally {
            isSignInLoading.value = false
          }
        }
      })
    }

    function signUpLink() {
      router.push({ name: "sign-up" })
    }
    function forgotPasswordLink() {
      router.push({ name: "password-reset" })
    }

    return {
      signUpLink,
      forgotPasswordLink,
      onSubmitSignIn,
      signIn,
      rules,
      ruleFormRef,
      isSignInLoading,
    }
  },
})
